<template>
  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="brand-logo">
                  <img src="../../../assets/images/logo.svg" />
                </div>
                <h4>Forgot Password</h4>
                <h6 class="font-weight-light">
                  Enter your email to change your password
                </h6>
                <ValidationObserver v-slot="{ invalid }">
                  <form @submit.prevent="onSubmit" class="pt-3">
                    <ValidationProvider name="email" rules="required|email">
                      <div slot-scope="{ errors }" class="form-group">
                        <input
                          type="email"
                          v-model="email"
                          class="form-control form-control-lg"
                          id="email"
                          placeholder="Email"
                        />
                        <p v-if="errors[0]" class="alert alert-danger">
                          {{ errors[0] }}
                        </p>
                        <p v-if="errors[1]" class="alert alert-danger">
                          {{ errors[1] }}
                        </p>
                      </div>
                    </ValidationProvider>

                    <div class="mt-3">
                      <b-button
                        type="submit"
                        :disabled="invalid"
                        variant="primary"
                        class="
                          btn btn-block btn-gradient-primary btn-lg
                          font-weight-medium
                          auth-form-btn
                        "
                        >{{ sign_in }}</b-button
                      >
                    </div>
                    <div
                      class="
                        my-2
                        mt-5
                        d-flex
                        justify-content-center
                        align-items-center
                      "
                    >
                      <router-link
                        to="/login"
                        class="small-text text-muted mb-0"
                        >Back to Login</router-link
                      >
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  name: "forgot-password",
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false,
      sign_in: "RESET PASSWORD",
    };
  },
  components: {
    ValidationObserver,
  },
  methods: {
    checkLogin() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$snotify.warning("User already logged in", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        });
        return false;
      }
      return true;
    },
    onSubmit() {
      this.sign_in = "PROCESSING...";
      if (!this.checkLogin()) return;
      const payload = {
        email: this.email,
      };
      localStorage.setItem("resetEmail", this.email);
      this.$store.dispatch("auth/passwordResetToken", payload).then((data) => {
        if (typeof data === "string") {
          this.sign_in = "RESET PASSWORD";
          this.$snotify.warning(data, {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          this.sign_in = "RESET PASSWORD";
          this.$snotify.success(
            "Otp sent succefully to your mail. \n Kindly check your mail",
            {
              timeout: 2000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
            }
          );
          this.$router.push("/reset-password");
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.simple-snotify {
  display: flex;
  justify-content: center;
}
.small-text {
  font-size: 12px;
}
</style>
